import Link from "next/link";

const ContentfulLink = ({ node, renderLink }) => {
	const label = node?.content?.[0]?.value;
	const href = node?.data?.uri || "#";

	if (typeof renderLink === "function") {
		return renderLink(label, href);
	}

	return (
		<Link prefetch={false} href={href} target={href?.startsWith?.("http") ? "_blank" : "_self"}>
			{label}
		</Link>
	);
};

export default ContentfulLink;
