import Cookies from "js-cookie";
import { getCustomerAxios } from "../utils/axios";
import { keys, getBackendURL, constants } from "../utils";

export const getCustomerData = async () => {
  const customerAxios = await getCustomerAxios();
  const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
    query: `query {
        customer {
          id
          cart_id
          firstname
          middlename
          lastname
          suffix
          email
          group_id
          is_subscribed
          allow_remote_shopping_assistance
          default_billing
          default_shipping
          addresses {
            id
            company
            firstname
            middlename
            lastname
            street
            city
            region {
              region_code
              region
            }
            postcode
            country_code
            telephone
            default_billing
            default_shipping
          }
        }
      }
		`,
  });
  return axiosRes.data;
};

export const updateBasicDetails = async (formdata) => {
  const { firstName, lastName, allow_remote_shopping_assistance } = formdata;
  const customerAxios = await getCustomerAxios();
  const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation {
      updateCustomerV2(
        input: {
          firstname: "${firstName}"
          lastname: "${lastName}"
          allow_remote_shopping_assistance: ${allow_remote_shopping_assistance}
        }
      ) {
        customer {
          firstname
          lastname
        }
      }
    }`,
  });
  return axiosRes.data;
};

export const changeEmail = async (formdata) => {
  const { email, password } = formdata;
  const customerAxios = await getCustomerAxios();
  const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation {
      updateCustomerEmail(
        email: "${email}",
        password: "${password}"
      ) {
        customer {
          email
        }
      }
    }`,
  });
  return axiosRes.data;
};

export const addAddress = async (formdata) => {
  const { county, quickAddress, street_main, ...rest } = formdata;
  const customerAxios = await getCustomerAxios();
  const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation CreateCustomerAddress($input: CustomerAddressInput!) {
      createCustomerAddress(input: $input) {
        id
        company
        firstname
        middlename
        lastname
        street
        city
        region {
          region_code
          region
        }
        postcode
        country_code
        telephone
        default_billing
        default_shipping
      }
    }`,
    variables: {
      input: {
        region: { region: county },
        country_code: "GB",
        ...rest,
      },
    },
  });
  return axiosRes.data;
};

export const editAddress = async ({ formdata, addressId }) => {
  const { county, quickAddress, street_copy, ...rest } = formdata;
  const customerAxios = await getCustomerAxios();
  const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation CreateCustomerAddress($input: CustomerAddressInput!) {
      updateCustomerAddress(id: ${addressId} , input: $input) {
        id
            company
            firstname
            middlename
            lastname
            street
            city
            region {
              region_code
              region
            }
            postcode
            country_code
            telephone
            default_billing
            default_shipping
      }
    }`,
    variables: {
      input: {
        region: { region: county },
        country_code: "GB",
        ...rest,
      },
    },
  });
  return axiosRes.data;
};

export const getAddressSuggestions = async ({ payload }) => {
  const customerAxios = await getCustomerAxios();
  const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
    query: `query FindAddresses(
      $text: String!
      $isMiddleware: Boolean = true
      $origin:String
      $container: String
      $countries: String!
      $language: String = "en-US"
      $bias: Boolean
      $limit: Int = 100
  ) {
      findAddress(
          text: $text
          isMiddleware: $isMiddleware
          origin: $origin
          container: $container
          countries: $countries
          language: $language
          bias: $bias
          limit: $limit
      ) {
          id
          description
          text
          type
          
      }
  }`,
    variables: {
      container: "",
      ...payload,
      origin: "",
      isMiddleware: true,
      countries: "gb",
      language: "en-gb",
      limit: 100,
    },
  });
  return axiosRes.data;
};

export const retrieveAddress = async ({ id = "" }) => {
  const customerAxios = await getCustomerAxios();
  const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
    query: `query RetrieveAddress(
      $id: String!
  ) {
      retrieveAddress(
          id: $id
      ) {
          secondary_street
          street
          block
          neighbourhood
          district
          city
          line1
          line2
          line3
          line4
          line5
          postal_code
      }
  }`,
    variables: {
      id,
    },
  });
  return axiosRes.data;
};

export const removeAddress = async ({ addressId }) => {
  const customerAxios = await getCustomerAxios();
  const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation {
      deleteCustomerAddress(id: ${addressId})
    }`,
  });
  return axiosRes.data;
};

/**
 * @param {{ email: string }} payload
 */
export const subscribeNewsletterLoggedInUser = async (payload) => {
  const { email } = payload;
  const customerAxios = await getCustomerAxios();
  const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation SubscribeEmailToNewsletter($email: String!) {
      subscribeEmailToNewsletter(email: $email) {
        status
      }
    }`,
    variables: {
      email,
    },
  });
  return axiosRes.data;
};

/**
 * @param {{ email: string }} payload
 */
export const unSubscribeNewsletterLoggedInUser = async (payload) => {
  const { email } = payload;
  const customerAxios = await getCustomerAxios();
  const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation UnsubscribeEmailToNewsletter($email: String!) {
      unsubscribeEmailToNewsletter(email: $email) {
        status
      }
    }`,
    variables: {
      email,
    },
  });
  return axiosRes.data;
};

export const getCustomerOrders = async ({
  currentPage,
  pageSize,
  limitedFields = false,
}) => {
  if (!Cookies.get(constants.cookies.customerToken)) {
    return { data: { customer: null } };
  }

  const customerAxios = await getCustomerAxios();
  let fields = ``;
  if (!limitedFields) {
    fields = `
      billing_address {
        firstname
        lastname
      }
      branch {
        name
      }
      payment_methods {
        name
        type
        additional_data {
          name
          value
        }
      }
      items {
        product_name
        product {
          image {
            url
            label
          }
          extraVariable {
            brand_info {
              id
              title
              image
            }
          }
        }
        quantity_ordered
        product_sale_price {
          value
          currency
        }
      }`;
  }

  const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
    query: `query {
      customer {
      cart_id,
        orders (
          scope: GLOBAL,
          currentPage: ${currentPage},
          pageSize: ${pageSize},
          sort: {
            sort_field: NUMBER,
            sort_direction: DESC
          }
        ) {
            total_count
            items {
              id
              status
              order_date
              order_number
              shipping_address {
                firstname
                lastname
              }
              total {
                grand_total
                { value currency }
              }
              ${fields}
            }
        }
      }
    }`,
  });
  return axiosRes.data;
};

export const getCustomerOrderDetails = async ({
  orderId = "",
  isServer = false,
}) => {
  const customerAxios = await getCustomerAxios(isServer);
  const axiosRes = await customerAxios.post(getBackendURL(), {
    query: `query { customer {
        orders(
           filter: {number: {eq: "${orderId}"}}
        ) {
            items {
                id
                order_number
                email
                shipping_method
                carrier
                 branch{
            branch_id
            hyperlink
            name
            address1
            address2
            address3
            address4
            postcode
            telephone
            email
            distance
            opening_mon_fri
            closing_mon_fri
            open_saturday
            opening_sat
            closing_sat
            open_sunday
            opening_sun
            closing_sun
        }
        payment_methods{
       name
       type
       additional_data{
           name
           value
       }
    }
                items {
                    product_name
                    product_sku
                    product{
                        image {
                                url
                                label
                            }
                            extraVariable{
                              brand_info{
                                id
                                  title
                                  image
                                }
                              }
                    }
                    quantity_ordered
                    product_sale_price{
                        value
                        currency
                    }
                }
                order_date               
               shipping_address {
                    firstname
                    lastname
                    middlename
                    region
                    region_id
                    country_code
                    street
                    company
                    telephone
                    fax
                    postcode
                    city
                }              
                billing_address {
                    firstname                   
                    lastname
                    middlename
                    region
                    region_id
                    country_code
                    street
                    company
                    telephone
                    fax
                    postcode
                    city
                }
                 total {
                    total_tax
                    { value currency }
                    taxes
                    { amount{ value currency } title rate }
                    subtotal
                    { value currency }
                    discounts{
                        amount { value currency }
                    }
                    grand_total
                    { value currency }
                    total_shipping
                    { value currency }
                    shipping_handling{
                            total_amount
                            { value currency } 
                            amount_including_tax 
                            { value currency }
                            amount_excluding_tax 
                            { value currency }
                    }
                    
                }
                status
            }
        }
  }}`,
  });
  return axiosRes.data;
};

export const reOrderExisting = async ({ orderId = "" }) => {
  const customerAxios = await getCustomerAxios();
  const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
    query: `mutation{
  reorderItems(orderNumber: "${orderId}"){
    cart {
      id
      itemsV2 {
        items {
          uid
          product {
            sku
          }
          quantity
          prices {
            price {
              value
            }
          }
        }
        total_count
        page_info {
          page_size
          current_page
          total_pages
        }          
      }
    }
    userInputErrors{
      code
      message
      path
    }
  }
}`,
  });
  return axiosRes.data;
};
