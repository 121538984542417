import { getEncryptedAPIKey, isValidAPIKey } from "../utils/crypto";
import { MVLQueryStringToKey, updateVehicleCookies } from "../utils";

const getRequestOptions = async (type, payload = {}) => {
  let options = {
    method: type,
    headers: {
      accept: "application/json",
      authorization: await getEncryptedAPIKey(),
    },
  };

  switch (type) {
    case "GET":
      //Revalidate after half a day.
      options["next"] = { revalidate: 43200 };
      break;
    case "POST":
      options["body"] = JSON.stringify(payload);
      break;
  }
  return options;
}

const sendPostRequest = async (endpoint, payload, resIsArray = false) => {
  let data = resIsArray ? [] : {};
  try {
    const options = await getRequestOptions("POST", payload);
    const res = await fetch(endpoint, options);
    data = await res.json();
  } catch (err) {
    console.error(err);
    data = resIsArray ? [] : {};
  }

  return data;
}

export const validateApiKey = async (apiToken) => {
  const isValid = await isValidAPIKey(apiToken);
  if (!isValid) {
    return Response.json({
      message: "Forbidden! You don't have permission to access.",
    }, { status: 401 });
  }
  return null;
}

export const getProductsStocks = async (payload) => {
  return await sendPostRequest("/api/check-stock", payload, true);
};

export const getDetailFromRedis = async (
  queryString,
  updateCookies = false,
) => {
  try {
    const options = await getRequestOptions("GET");
    const res = await fetch(`/api/vehicle-info?${queryString}`, options);
    const json = await res.json();
    if (updateCookies && json?.data) {
      let key = "";
      if (queryString.includes("vrm")) {
        key = queryString.split("=")[1];
      } else {
        key = MVLQueryStringToKey(queryString);
      }
      updateVehicleCookies({ key });
    }
    return json.data || null;
  } catch (err) {
    console.error({ err });
    return null;
  }
};

export const getNearestBranches = async (payload) => {
  return await sendPostRequest("/api/nearest-branches", payload);
};

export const signupUser = async (payload) => {
  let res = { data: null, error: null };
  try {
    const options = await getRequestOptions("POST", payload);
    const apiRes = await fetch("/api/signup", options);
    const jsonData = await apiRes.json();
    if (apiRes?.status === 200) {
      res.data = jsonData;
    } else {
      res.error = jsonData.message;
    }
  } catch (err) {
    console.error(err);
    res.error = err;
  }

  return res;
}

export const signinUser = async (payload) => {
  return await sendPostRequest("/api/signin", payload);
}

export const changeUserPassword = async (payload) => {
  return await sendPostRequest("/api/change-password", payload);
}

export const createNewPassword = async (payload) => {
  return await sendPostRequest("/api/create-new-password", payload);
}