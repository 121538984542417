"use client";

import Link from "next/link";
import { useMemo } from "react";
import classNames from "classnames";
import { routes } from "@/lib/utils";
import InfoIcon from "./SvgIcons/InfoIcon";
import CloseIcon from "./SvgIcons/CloseIcon";
import ErrorIcon from "./SvgIcons/ErrorIcon";
import { useTranslation } from "@/i18n/client";
import SuccessIcon from "./SvgIcons/SuccessIcon";
import OptimizedImage from "../../OptimizedImage";
import { useTouchHandler } from "@/lib/hooks/useTouchHandler";
import Touchable from "../Touchable";
import { useRouter } from "next/navigation";
import { toast } from "react-toastify";

/**
 * @param {import("react-toastify/dist/types").ToastContentProps & {
 * 	message: string | () => React.ReactNode,
 * }} props
 * @returns
 */
export default function CustomToaster(props) {
  const { message, ...rest } = props;
  const { closeToast, toastProps } = rest;
  const { type, heading } = toastProps;

  const { t } = useTranslation(["common"]);
  const toastData = useMemo(() => {
    return {
      success: {
        className: "success-toastify",
        icon: <SuccessIcon />,
        heading: t("messages.toastHeadings.success"),
      },
      error: {
        className: "error-toastify",
        icon: <ErrorIcon />,
        heading: t("messages.toastHeadings.error"),
      },
      info: {
        className: "info-toastify",
        icon: <InfoIcon />,
        heading: t("messages.toastHeadings.info"),
      },
    };
  }, [t]);

  return (
    <div
      className={classNames(
        "flex items-center gap-4 w-full xs:max-w-[401px] xs:min-w-[401px] max-w-[321px] min-w-[321px]",
        toastData[type].className,
      )}
    >
      <div>{toastData[type].icon}</div>
      <div className="">
        <h5 className="toast-title text-subtitle-4-b">
          {heading || toastData[type].heading}
        </h5>
        <p className="toast-msg">
          {typeof message === "function" ? message() : message}
        </p>
      </div>
      <button className="close-icon-box ml-auto" onClick={closeToast}>
        <CloseIcon />
      </button>
    </div>
  );
}

export function ProductToast(props) {
  const { name, image, showCTA = true } = props;
  const { t } = useTranslation(["common"]);
  // const touchHandlers = useTouchHandler();
  const router = useRouter();

  const handleRedirect = () => {
    router.push(routes.basket); // Replace with your destination URL
    toast.dismiss();
  };

  return (
    <div
      className={classNames(
        "flex gap-[21px] w-full flex-col max-w-[342px]",
        "basket-toastify",
      )}
    >
      <div className="flex items-center gap-3">
        <div className="w-[80px] min-w-[80px] max-w-[80px] flex items-center justify-center aspect-square rounded border border-primary-black-50 p-2 h-[80px] min-h-[80px] max-h-[80px]">
          <OptimizedImage
            width={80}
            alt={name}
            src={image}
            height={80}
            className="h-[64px] w-[64px] object-contain"
          />
        </div>
        <div>
          <h5 className="toast-title">
            &quot;<span className="capitalize">{name}</span>&quot;{" "}
            {t("product.toast.product_added_text")}
          </h5>
        </div>
      </div>
      {showCTA && (
        <Touchable
          onTouch={handleRedirect}
          className="flex w-full"
          preventDefault={false}
        >
          <Link
            // {...touchHandlers}
            href={routes.basket}
            // onTouchStart={handleRedirect}
            onClick={(e) => {
              e.preventDefault();
              toast.dismiss();
            }}
            className="green-btn w-full text-center"
          >
            {t("product.toast.go_to_basket")}
          </Link>
        </Touchable>
      )}
      <button className="absolute top-[8px] right-[8px]">
        <CloseIcon />
      </button>
    </div>
  );
}

export function StoreToast(props) {
  const { name } = props;
  const { t } = useTranslation(["common"]);
  const touchHandlers = useTouchHandler();

  return (
    <div className="w-12/12 flex justify-center">
      <div
        className={classNames(
          "flex gap-4 w-full  xs:max-w-[709px] max-w-[343px]",
          "success-toastify",
        )}
      >
        <div className="w-[52px] min-w-[52px] max-w-[52px] aspect-square">
          <SuccessIcon />
        </div>
        <div className="flex xs:flex-row flex-col xs:items-center items-start">
          <p className="toast-title text-body-text-3 max-w-[509px]">
            &quot;{name}&quot; {t("messages.branch_toast.text")}
          </p>

          <Link
            {...touchHandlers}
            href={routes.parts}
            className="text-body-text-3-sb text-primary-red underline whitespace-nowrap"
          >
            {t("messages.branch_toast.btn")}
          </Link>
        </div>
      </div>
    </div>
  );
}
