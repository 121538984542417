import React, { useEffect, useRef } from 'react';

const Touchable = ({ onTouch, className, children, preventDefault = true }) => {
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;

    if (!element) return;

    const handleTouchStart = (e) => {
      if (preventDefault) {
        e.preventDefault();
      }
      onTouch();
    };

    element.addEventListener('touchstart', handleTouchStart, { passive: !preventDefault });

    return () => {
      element.removeEventListener('touchstart', handleTouchStart);
    };
  }, [onTouch, preventDefault]);

  return (
    <div
      ref={ref}
      className={className}
      role="button" // Ensures accessibility
      tabIndex={0} // Allows keyboard focus
      onClick={onTouch}
    >
      {children}
    </div>
  );
};

export default Touchable;
