"use client";
import { useState, useRef } from 'react';

export const useTouchHandler = (onTapCallback, doubleTapDelay = 500) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const lastTapRef = useRef(0);

  const handleTouchStart = () => {
    setIsScrolling(false);
  };

  const handleTouchMove = () => {
    setIsScrolling(true);
  };

  const handleTouchEnd = (event) => {
    if (!isScrolling) {
      const currentTime = new Date().getTime();
      const tapLength = currentTime - lastTapRef.current;

      if (tapLength < doubleTapDelay && tapLength > 0) {
        // Double-tap detected, execute callback
        onTapCallback(event);
      }

      // Update the last tap time
      lastTapRef.current = currentTime;
    }
  };

  return {
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onTouchEnd: handleTouchEnd,
  };
};
