export const keys = {
  general: {
    websiteUrl: process.env.NEXT_PUBLIC_WEBSITE_URL,
    backendGraphqlUrl: process.env.NEXT_PUBLIC_BACKEND_GRAPHQL_URL,
    graphqlUrl: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    restUrl: process.env.NEXT_PUBLIC_REST_URL,
    zendeskKey: process.env.NEXT_PUBLIC_ZENDESK_KEY,
    addExtension: process.env.NEXT_PUBLIC_APP_PAGE_EXTENSION === "1",
    checkStockKey: process.env.NEXT_PUBLIC_CHECK_STOCK_KEY,
    checkStockUrl: process.env.NEXT_PUBLIC_CHECK_STOCK_URL,
    reviewAPIUrl: process.env.NEXT_PUBLIC_REVIEW_API,
    apiKey: process.env.NEXT_PUBLIC_API_SECRET,
    enableBot: process.env.NEXT_PUBLIC_ENABLE_BOT,
  },
  google: {
    recaptchaV3Key: process.env.NEXT_PUBLIC_RECAPTCHA_V3_SITE_KEY,
    mapsKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY,
    gtmId: process.env.NEXT_PUBLIC_GTM_ID,
  },
  crypto: {
    algorithm: process.env.NEXT_PUBLIC_ALGORITHM,
    initVector: process.env.NEXT_PUBLIC_INIT_VECTOR,
    secretKey: process.env.NEXT_PUBLIC_SECRET_KEY,
  },
  contentful: {
    spaceId: process.env.CONTENTFUL_SPACE_ID,
    env: process.env.CONTENTFUL_ENV,
    accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
    previewAccessToken: process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN,
    previewHost: process.env.CONTENTFUL_PREVIEW_HOST,
  },
  redis: {
    host: process.env.NEXT_PUBLIC_REDIS_HOST,
    port: process.env.NEXT_PUBLIC_REDIS_PORT,
    expiry: process.env.NEXT_PUBLIC_REDIS_EXIRY_DAY || 1,
  },
};
