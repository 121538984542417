"use client";

import Image from "next/image";
import { constants } from "@/lib/utils";
import { useAppSelector } from "@/lib/store";
import { getGlobalArgs } from "@/lib/store/reducers/common";
import { useState, useCallback, useEffect, useRef } from "react";

import brand from "@/assets/static/brand_placeholder.png";
import brandSM from "@/assets/static/brand_placeholder_sm.png";
import placeholder from "@/assets/static/product_placeholder.webp";
import productBadge from "@/assets/static/product_badge_placeholder.png";
import brandRecommended from "@/assets/static/brand_recommended_placeholder.png";

const OptimizedImage = ({ className, type = "", size = "", ...rest }) => {
  const mounted = useRef();
  const [imageSrc, setImageSrc] = useState("");
  const { bestseller, recommended } = useAppSelector(getGlobalArgs) || {};
  const fallbackImage = useCallback(() => {
    let defaultImg;
    switch (type) {
      case constants.image_types.brand:
        defaultImg = brand;
        if (size === "small") defaultImg = brandSM;
        break;
      case constants.image_types.product:
      case constants.image_types.category:
        defaultImg = placeholder;
        break;
      case constants.image_types.product_recommended:
      case constants.image_types.product_best_seller:
        defaultImg = productBadge;
        break;
      case constants.image_types.brand_recommended:
        defaultImg = brandRecommended;
        break;
      default:
        defaultImg = placeholder;
        break;
    }
    return defaultImg.src;
  }, [type, size]);

  const onLoadError = () => {
    setImageSrc(fallbackImage());
  };

  useEffect(() => {
    if (!mounted.current) {
      let imageURL = rest.src;
      if (!imageURL || imageURL === undefined) {
        if (type === constants.image_types.product_best_seller) {
          imageURL = bestseller;
        } else if (type === constants.image_types.product_recommended) {
          imageURL = recommended;
        } else if (type === constants.image_types.brand_recommended) {
          imageURL = recommended;
        } else {
          imageURL = fallbackImage();
        }
      }
      setImageSrc(imageURL);
      mounted.current = true;
    } else if (type === constants.image_types.product_best_seller) {
      setImageSrc(bestseller);
    } else if (type === constants.image_types.product_recommended) {
      setImageSrc(recommended);
    } else if (type === constants.image_types.brand_recommended) {
      setImageSrc(recommended);
    }
  }, [bestseller, fallbackImage, size, rest, recommended, type]);

  if (!imageSrc) return null;

  return (
    <Image
      {...rest}
      src={imageSrc}
      width={rest.width}
      height={rest.height}
      className={className}
      onError={onLoadError}
      alt={rest.alt || "Image"}
      sizes="(max-width: 768px) 100vw, 
      (max-width: 1200px) 50vw, 
      33vw" // How the image scales at different breakpoints
    />
  );
};

export default OptimizedImage;
