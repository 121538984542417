import * as contentful from "contentful";
import { constants, keys } from "../utils";

const contentfulConfig = {
  space: keys.contentful.spaceId,
  environment: keys.contentful.env,
  accessToken: keys.contentful.accessToken,
};

const contentfulPreviewConfig = {
  space: keys.contentful.spaceId,
  environment: keys.contentful.env,
  accessToken: keys.contentful.previewAccessToken,
  host: keys.contentful.previewHost,
};

export const getContentFulClient = (preview = false) => {
  if (preview) {
    return contentful.createClient(contentfulPreviewConfig);
  } else {
    return contentful.createClient(contentfulConfig);
  }
};

export const getContentfulImgData = (imageFile) => {
  const {
    title,
    description,
    file: {
      fileName,
      contentType,
      url,
      details: { size, image: { width = 0, height = 0 } = {} } = {},
    } = {},
  } = imageFile?.fields || imageFile || {};

  const kiloBytes = size ? size / 1000 : null;
  let imageUrl = url ? "https:" + url : null;

  let quality = 100;

  if (kiloBytes >= 300 && kiloBytes < 500) {
    quality = 90;
  } else if (kiloBytes >= 500 && kiloBytes < 700) {
    quality = 80;
  } else if (kiloBytes >= 700) {
    quality = 75;
  }

  switch (contentType) {
    case "image/jpeg":
      imageUrl = imageUrl?.concat?.("?q=", quality);
      break;
    case "image/gif":
      imageUrl = imageUrl?.concat?.("?fm=avif&q=", quality);
      break;
  }

  return {
    title,
    description,
    imageUrl,
    width,
    height,
    fileName,
  };
};

/**
 * @param {import("contentful").ContentfulClientApi} client
 * @param {import("contentful").EntriesQueries & { preview?: boolean }} params
 * @returns {Promise<[data, error]>}
 */
export const getContentfulData = async (params) => {
  const client = getContentFulClient(params.preview);
  try {
    const res = await client.getEntries({
      ...params,
      include: 10,
    });
    return [res, null];
  } catch (err) {
    let customErr;
    try {
      customErr = JSON.parse(err.message);
    } catch (parseErr) {
      customErr = err;
    }
    return [null, customErr?.message || "Contentful Error"];
  }
};

export const getPrivacyPolicyPage = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.cmsPage,
    "fields.slug": constants.contentfulSlugs.privacyPolicy,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getCookiePolicyPage = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.cmsPage,
    "fields.slug": constants.contentfulSlugs.cookiePolicy,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getTermsAndConditionsPage = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.cmsPage,
    "fields.slug": constants.contentfulSlugs.termsAndConditions,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getStoreLocatorPage = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.cmsPage,
    "fields.slug": constants.contentfulSlugs.storeLocator,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getReturnsAndRefundsPage = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.cmsPage,
    "fields.slug": constants.contentfulSlugs.returnsAndRefunds,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getContactUsPage = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.contactUs,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getHomePage = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.homePage,
    limit: 1,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getSignupPage = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.signupPage,
    limit: 1,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getDeliveryInfoPage = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.deliveryInfoPage,
    limit: 1,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getKnowledgeHubListingPage = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.knowledgeHubListingPage,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getKnowledgeHubBlog = async (param = {}) => {
  const [blogDetail, blogErr] = await getContentfulData({
    content_type: constants.contentfulTypes.knowledgeHubBlog,
    ...param,
  });

  return {
    blogDetail,
    blogErr,
  };
};

export const getClickAndCollectPage = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.cmsPage,
    "fields.slug": constants.contentfulSlugs.clickAndCollect,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getAboutUsPage = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.aboutUs,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getMegaMenu = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.megaMenu,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getKlarnaPage = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.cmsPage,
    "fields.slug": constants.contentfulSlugs.klarna,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getDealsPage = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.dealsPage,
    limit: 1,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getProductTabs = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.cmsPageSubsection,
    "fields.sectionId": constants.contentfulSlugs.pdpTabs,
    limit: 1,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getWhyGSF = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.whyGsf,
    limit: 1,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getPLPBanner = async (lastSegment) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.plpBanner,
    "fields.chooseCategory": lastSegment,
    limit: 1,
  });

  if (error || data.items.length === 0) {
    const [defaultData, defaultError] = await getContentfulData({
      content_type: constants.contentfulTypes.plpBanner,
      "fields.chooseCategory": "default", // Use the specific field value for the default banner
      limit: 1,
    });

    return {
      data: defaultData,
      error: defaultError,
    };
  }

  return { data, error };
};

export const getAccessoriesPageData = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.categoryPage,
    "fields.slug": constants.contentfulSlugs.accessories,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getToolsPageData = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.categoryPage,
    "fields.slug": constants.contentfulSlugs.tools,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getMakesModels = async (params = {}) => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.makesAndModels,
    limit: 1,
    ...params,
  });

  return {
    data,
    error,
  };
};

export const getOrgnizationInfo = async () => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.organisation,
  });

  return { data, error };
};


export const getToolsPage = async () => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.menuItem,
    "sys.id": constants.contentfulSlugs.entryId,
  });

  return { data, error };
};

export const getSitemapLinks = async () => {
  const [data, error] = await getContentfulData({
    content_type: constants.contentfulTypes.sitemapLinks,
  });

  return { data, error };
};
