import axios from "axios";
import Cookies from "js-cookie";
import { productFragments } from "./fragments";
import { getCustomerAxios } from "../utils/axios";
import { keys, handleGraphqlResponse, getBackendURL } from "../utils";

export const resolveURL = async ({ path }) => {
  try {
    let paramsString = `url: "${path}"`;
    const axiosRes = await axios.post(keys.general.graphqlUrl, {
      query: `query {
        urlResolver(${paramsString}) {
          id
          sku
          type
          level
          entity_uid
          relative_url
          redirectCode
          display_mode
          has_children
          component_id
          is_threebo_view
          is_type_service_part
          breadcrumbs {
            label
            link
            id
            component_id
          }
        }
      }`,
    });

    const res = handleGraphqlResponse(axiosRes);
    return { data: res.data, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getCategoryMeta = async ({ categoryid }) => {
  try {
    const variables = {
      categoryfilters: {
        ids: {
          in: [categoryid],
        },
      },
    };

    const query = `query ProductFilter (
      $categoryfilters: CategoryFilterInput
    ) {
      categoryList (filters: $categoryfilters) {
        id
        name
        image
        meta_title
        meta_keywords
        meta_description
      }
    }`;

    const axiosRes = await axios.post(keys.general.graphqlUrl, {
      query,
      variables,
    });
    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getProductMeta = async ({ productSKU }) => {
  try {
    const variables = {
      productfilters: { sku: { eq: productSKU } },
    };

    const query = `query ProductFilter (
      $productfilters: ProductAttributeFilterInput
    ) {
      products (
        filter: $productfilters
      ) {
        items {
          id
          name
          meta_title
          meta_keyword
          meta_description
          extraVariable (breadcrumb_required: true) {
            brand_info { title }
          }
          thumbnail {
            url
            label
          }
        }
      }
    }`;

    const axiosRes = await axios.post(keys.general.graphqlUrl, {
      query,
      variables,
    });
    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getCategoryDetail = async ({ categoryid }) => {
  try {
    const variables = {
      categoryfilters: {
        ids: {
          in: [categoryid],
        },
      },
    };

    const query = `query ProductFilter (
      $categoryfilters: CategoryFilterInput
    ) {
      categoryList (filters: $categoryfilters) {
        ...Category
      }
    }
    ${productFragments.category}`;

    const axiosRes = await axios.post(getBackendURL(), {
      query,
      variables,
    });
    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getProductListing = async ({
  page,
  pageSize,
  sortOrder,
  categoryid,
  specialCoupon,
  filtersObj = {},
  skuList = null,
  vehicleFilter = null,
}) => {
  try {
    let productfilters = {
      category_id: { eq: categoryid },
      price: { from: 0.01 },
    };
    if (skuList) {
      productfilters["sku"] = { in: skuList };
    }

    if (
      vehicleFilter &&
      vehicleFilter?.session_id &&
      vehicleFilter?.component_id
    ) {
      productfilters["vrmFilter"] = vehicleFilter;
    }

    for (const key in filtersObj) {
      if (!["page", "make", "model"].includes(key)) {
        productfilters[key] = { in: filtersObj[key] };
      }
    }

    const variables = {
      currentPage: parseInt(page),
      productfilters: productfilters,
      productPageSize: pageSize,
      productSort: {
        [sortOrder.field]: sortOrder.direction.toUpperCase(),
      },
    };

    const query = `query ProductFilter (
      $currentPage: Int,
      $productPageSize: Int
      $productSort: ProductAttributeSortInput
      $productfilters: ProductAttributeFilterInput
      $aggregationsFilter: AggregationsFilterInput
    ) {
      products (
        sort: $productSort
        filter: $productfilters
        currentPage: $currentPage
        pageSize: $productPageSize
        specialCoupon: "${specialCoupon || ""}"
      ) {
        aggregations (filter: $aggregationsFilter) {
          ...AggregationFragment
        }
        items {
          ...Products
        }
        total_count
      }
    }
    ${productFragments.listing}
    ${productFragments.aggregation}`;
    const axiosRes = await axios.post(getBackendURL(), { query, variables });
    const res = handleGraphqlResponse(axiosRes.data);

    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getProductDetail = async ({ productSKU, specialCoupon }) => {
  try {
    const variables = {
      productfilters: { sku: { eq: productSKU } },
    };

    const query = `query ProductFilter (
      $productfilters: ProductAttributeFilterInput
    ) {
      products (
        filter: $productfilters
        specialCoupon: "${specialCoupon || ""}"
      ) {
        items {
          ...Product
        }
      }
    }
    ${productFragments.detail}`;

    const axiosRes = await axios.post(getBackendURL(), {
      query,
      variables,
    });
    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getProductPrice = async ({ productSKU, specialCoupon }) => {
  try {
    const variables = {
      productfilters: { sku: { eq: productSKU } },
    };

    const query = `query ProductFilter (
      $productfilters: ProductAttributeFilterInput
    ) {
      products (
        filter: $productfilters
        specialCoupon: "${specialCoupon || ""}"
      ) {
        items {
          sku,
          special_price,
          specialCoupon_applied,
          price_range {
            minimum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
            }
          }
        }
      }
    }`;

    const axiosRes = await axios.post(getBackendURL(), {
      query,
      variables,
    });
    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

//get featured  product for deals page
export const getFeaturedDeals = async (specialCoupon) => {
  try {
    const query = `query FeaturedAndSeasonalProducts(
      $featuredFilter: ProductAttributeFilterInput,
      $featuredSort: ProductAttributeSortInput,
      $featuredPageSize: Int
    ) {
      featured_products: products(
        filter: $featuredFilter,
        sort: $featuredSort,
        pageSize: $featuredPageSize
        specialCoupon: "${specialCoupon?.value || ""}"
      ) {
        total_count
        items {
          ...Products
        }
      }
    }
    ${productFragments.listing}`;

    const variables = {
      featuredFilter: {
        featured_product: {
          eq: 1,
        },
        price: { from: 0.01 },
      },
      featuredSort: {
        featured_product_sort_order: "ASC",
      },
      featuredPageSize: 24, // Example value, adjust as needed
    };

    const response = await axios.post(getBackendURL(), {
      query,
      variables,
    });

    if (response.data.errors) {
      console.error("GraphQL errors:", response.data.errors);
      throw new Error("GraphQL request failed");
    }

    return { data: response.data.data, error: null };
  } catch (error) {
    console.error("Error fetching featured deals:", error);
    return { data: [], error: error.message };
  }
};

export const searchProductsAndCategories = async ({
  searchText,
  pageSize = 8,
  currentPage = 1,
  catPageSize = 10,
  catCurrentPage = 1,
  specialCoupon,
}) => {
  try {
    const textWithoutQuotes = searchText.replaceAll('"', "");
    const query = `query {
      products (
        search: "${textWithoutQuotes}",
        pageSize: ${pageSize},
        currentPage: ${currentPage},
        catPageSize: ${catPageSize},
        catCurrentPage: ${catCurrentPage},
        specialCoupon: "${specialCoupon || ""}",
        filter: {
      price: {
        from: 0.01
  }}
      ) {
        search_trems {
          query_text
          redirect
        }
        category_count
        categories {
          name
          link
          image
        }
        total_count
        items {
          ...Products
        }
      }
    }
    ${productFragments.listing}`;
    const axiosRes = await axios.post(getBackendURL(), { query });
    const res = handleGraphqlResponse(axiosRes.data);

    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getCategoryPage = async ({ catId, showChild = true }) => {
  try {
    const variables = {
      filters: {
        ids: {
          in: [catId],
        },
      },
    };

    let query = `
      query getCategoryDetails($filters: CategoryFilterInput) {
        categories(filters: $filters) {
          total_count
          items {
            uid
            id
            name
            path
            image
            level
            url_key
            url_path
            children_count
            component_id
            breadcrumbs {
              category_id
              category_name
              category_level
              category_url_path
              category_url_key
            }
            children {
              uid
              name
              path
              image
              level
              url_key
              url_path
              component_id
              children_count
              children {
                id
                name
                path
                image
                level
                url_key
                url_path
                component_id
              }
            }
          }
          page_info {
            page_size
            current_page
            total_pages
          }
        }
      }
    `;

    if (!showChild) {
      query = `query getCategoryDetails($filters: CategoryFilterInput) {
        categories(filters: $filters) {
          total_count
          items {
            id
            uid
            level
            name
            path
            url_path
            url_key
            children_count
            children {
              uid
              level
              name
              path
              url_path
              url_key
              image
            }
          }
        }
      }
    `;
    }

    const axiosRes = await axios.post(getBackendURL(), {
      query,
      variables,
    });
    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getFeaturedProducts = async (productType, specialCoupon) => {
  try {
    const variables = {
      productFilter: {
        [productType]: {
          eq: 1,
        },
        price: { from: 0.01 },
      },
      productSort: {
        name: "ASC",
      },
      PageSize: 4,
    };

    const query = `
      query showOnBasketProducts(
        $productFilter: ProductAttributeFilterInput
        $productSort: ProductAttributeSortInput
        $pageSize: Int
      ) {
        products: products(
          filter: $productFilter
          sort: $productSort
          pageSize: $pageSize
          specialCoupon: "${specialCoupon.value || ""}"
        ) {
          total_count
          items {
            ...Products
          }
        }
      }
      ${productFragments.listing}`;

    const axiosRes = await axios.post(getBackendURL(), {
      query,
      variables,
    });
    const res = handleGraphqlResponse(axiosRes.data);
    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const getSubCategoryData = async ({ categoryid }) => {
  try {
    const variables = {
      filters: {
        ids: {
          in: [categoryid],
        },
      },
    };

    const query = `
      query GetSubCategoryData($filters: CategoryFilterInput) {
        categories(filters: $filters) {
          total_count
          items {
            uid
            id
            level
            name
            path
            url_path
            url_key
            image
            description
            breadcrumbs {
              category_id
              category_name
              category_level
              category_url_path
              category_url_key
            }
            children_count
            children {
              uid
              id
              level
              name
              path
              url_path
              url_key
              image
              children_count
              breadcrumbs {
                category_id
                category_name
                category_level
                category_url_path
                category_url_key
              }
              children {
                id
                level
                name
                path
                url_path
                url_key
                image
              }
            }
          }
          page_info {
            current_page
            page_size
            total_pages
          }
        }
      }
    `;

    const axiosRes = await axios.post(getBackendURL(), {
      query,
      variables,
    });
    const res = handleGraphqlResponse(axiosRes.data);

    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

// check vehicle compatibility
export const checkVehicleCompatability = async ({
  sku,
  sessionId,
  componentId,
  categoryid,
}) => {
  const specialCoupon = Cookies.get("specialCoupon");
  const axiosRes = await axios.get(
    `${keys.general.restUrl}/V1/gsf-vrm/vehiclecompatibility?sku=${sku}&sessionId=${sessionId}&componentId=${componentId}&categoryId=${categoryid}&specialCoupon=${specialCoupon}`,
  );
  return axiosRes.data;
};

export const getCarPartsCategories = async ({ catId, showChild = true }) => {
  try {
    const variables = {
      filters: {
        ids: {
          in: [catId],
        },
      },
    };

    const withSubCat = `{
      id
      name
      breadcrumbs {
        category_id
        category_name
        category_level
        category_url_path
        category_url_key
      }
      children {
        id
        name
        image
        url_path
        component_id
        children {
          id
          name
          image
          url_path
          component_id
        }
      }
    }`;

    const withoutSubCat = `{
      id
      name
      breadcrumbs {
        category_id
        category_name
        category_level
        category_url_path
        category_url_key
      }
      children {
        id
        name
        image
        url_path
        component_id
      }
    }`;

    const query = `query getCategoryDetails ($filters: CategoryFilterInput) {
        categories(filters: $filters) {
          items ${!showChild ? withoutSubCat : withSubCat}
        }
      }
    `;

    const axiosRes = await axios.post(getBackendURL(), { query, variables });
    const res = handleGraphqlResponse(axiosRes.data);

    return { data: res, error: null };
  } catch (error) {
    return { data: [], error: error.message };
  }
};

export const notifyStockAlertMail = async (product_id) => {
  try {
    const currentAxios = await getCustomerAxios();
    const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
      query: `mutation ProductAlertStock($input: ProductAlertInput!) {
  productAlertStock(input: $input){
    status
  }
}
`,
      variables: {
        input: {
          product_id: product_id,
        },
      },
    });
    const res = handleGraphqlResponse(axiosRes);
    let err;
    if (res?.errors) {
      err = res?.errors[0].message;
    } else {
      err = null;
    }
    return { data: res.data, error: err };
  } catch (error) {
    return { data: null, error };
  }
};

export const unsubscribeNotifyStockAlertMail = async () => {
  try {
    const currentAxios = await getCustomerAxios();
    const axiosRes = await currentAxios.post(keys.general.backendGraphqlUrl, {
      query: `mutation{
    unSubscribeAllProductStock{
        status
    }
}
`,
    });
    const res = handleGraphqlResponse(axiosRes);
    let err;
    if (res?.errors) {
      err = res?.errors[0].message;
    } else {
      err = null;
    }
    return { data: res.data, error: err };
  } catch (error) {
    return { data: null, error };
  }
};
