const InfoIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="26" cy="26" r="26" fill="#FFDDBD" />
      <g filter="url(#filter0_d_4840_54875)">
        <circle cx="26" cy="26" r="19" fill="white" />
      </g>
      <mask
        id="mask0_4840_54875"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="14"
        y="14"
        width="24"
        height="24"
      >
        <rect x="14" y="14" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4840_54875)">
        <path
          d="M25.25 30.75H26.75V25H25.25V30.75ZM26 23.2885C26.2288 23.2885 26.4207 23.2111 26.5755 23.0563C26.7303 22.9015 26.8077 22.7096 26.8077 22.4808C26.8077 22.2519 26.7303 22.0601 26.5755 21.9053C26.4207 21.7505 26.2288 21.6731 26 21.6731C25.7711 21.6731 25.5793 21.7505 25.4245 21.9053C25.2697 22.0601 25.1923 22.2519 25.1923 22.4808C25.1923 22.7096 25.2697 22.9015 25.4245 23.0563C25.5793 23.2111 25.7711 23.2885 26 23.2885ZM26.0017 35.5C24.6877 35.5 23.4527 35.2506 22.2966 34.752C21.1404 34.2533 20.1347 33.5765 19.2795 32.7217C18.4243 31.8669 17.7472 30.8616 17.2483 29.706C16.7495 28.5504 16.5 27.3156 16.5 26.0017C16.5 24.6877 16.7494 23.4527 17.248 22.2966C17.7467 21.1404 18.4234 20.1347 19.2783 19.2795C20.1331 18.4243 21.1384 17.7472 22.294 17.2483C23.4496 16.7494 24.6844 16.5 25.9983 16.5C27.3123 16.5 28.5473 16.7493 29.7034 17.248C30.8596 17.7467 31.8653 18.4234 32.7205 19.2783C33.5757 20.1331 34.2528 21.1383 34.7516 22.294C35.2505 23.4496 35.5 24.6844 35.5 25.9983C35.5 27.3122 35.2506 28.5473 34.752 29.7034C34.2533 30.8596 33.5766 31.8652 32.7217 32.7205C31.8669 33.5757 30.8616 34.2527 29.706 34.7516C28.5504 35.2505 27.3156 35.5 26.0017 35.5ZM26 34C28.2333 34 30.125 33.225 31.675 31.675C33.225 30.125 34 28.2333 34 26C34 23.7666 33.225 21.875 31.675 20.325C30.125 18.775 28.2333 18 26 18C23.7667 18 21.875 18.775 20.325 20.325C18.775 21.875 18 23.7666 18 26C18 28.2333 18.775 30.125 20.325 31.675C21.875 33.225 23.7667 34 26 34Z"
          fill="#FF5A00"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4840_54875"
          x="0"
          y="0"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4840_54875"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4840_54875"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default InfoIcon;
