import { getCustomerData } from "@/lib/services/customer";
import {
  getAddressFromId,
  getCustomerName,
  handleGraphqlResponse,
} from "@/lib/utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  details: null,
};

export const fetchCustomer = createAsyncThunk(
  "customer/fetchCustomer",
  async () => {
    try {
      const res = await getCustomerData();
      const data = handleGraphqlResponse(res);
      //Transform the response
      data.customer.fullname = getCustomerName(data.customer);
      data.customer.default_billing = getAddressFromId(
        data.customer.default_billing,
        data.customer.addresses,
      );
      data.customer.default_shipping = getAddressFromId(
        data.customer.default_shipping,
        data.customer.addresses,
      );
      return data.customer;
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

const name = "customer";
const customer = createSlice({
  name,
  initialState,
  reducers: {
    setCustomerToken(state, action) {
      const { payload } = action;
      state.token = payload;
    },
    logoutUser() {
      return initialState;
    },
    updateCustomerDetails(state, action) {
      const { payload = {} } = action;
      state.details = {
        ...state.details,
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomer.fulfilled, (state, action) => {
      const { payload } = action;
      state.details = payload;
    });
  },
});

//Export Actions Creators
export const { setCustomerToken, logoutUser, updateCustomerDetails } =
  customer.actions;

//Export your selectors
export const getCustomerToken = (state) => state.customer.token;
export const getCustomerDetails = (state) => state.customer.details;

export default customer;
