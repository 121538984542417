import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

//Import your reducers here.
import common from "./reducers/common";
import customer from "./reducers/customer";
import cart from "./reducers/cart";

export const makeStore = () => {
  return configureStore({
    reducer: {
      [common.name]: common.reducer,
      [customer.name]: customer.reducer,
      [cart.name]: cart.reducer,
    },
    devTools: process.env.NODE_ENV !== "production",
  });
};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes();
export const useAppSelector = useSelector.withTypes();
