import moment from "moment-timezone";
import { handleGraphqlResponse } from "@/lib/utils";
import { getGloablAssets } from "@/lib/services/general";
import { getVehicleDetails } from "@/lib/services/garage";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getVehicleManufacturers } from "@/lib/services/vehicles";

const initialState = {
  loading: 0,
  branch: null,
  timezone: "",
  vehicle: null,
  tagIcons: null,
  breadcrumbs: [],
  makeOptions: [],
  storeConfig: {},
  garageVehicle: {},
  miniTrolley: false,
  activeMenuKey: null,
  activeSidebar: false,
  activeAlphabet: null,
  showCheckStock: true,
  manualSessionId: null,
  appInitailized: false,
  GSF_telephone: "",
  lastSearchedTerm: "",
};

export const fetchGlobalAssets = createAsyncThunk(
  "common/fetchTagIcons",
  async () => {
    try {
      const res = await getGloablAssets();
      const data = handleGraphqlResponse(res);
      //Transform the response
      return data;
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

export const fetchGarageVehicleDetails = createAsyncThunk(
  "common/fetchGarageVehicleDetails",
  async (payload) => {
    if (payload === null) {
      return {};
    }

    try {
      const res = await getVehicleDetails(payload);
      const data = handleGraphqlResponse(res);
      return data;
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

export const fetchMakeOptions = createAsyncThunk(
  "common/fetchMakeOptions",
  async () => {
    try {
      return await getVehicleManufacturers();
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
);

const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    startCommonLoading(state) {
      state.loading += 1;
    },
    endCommonLoading(state) {
      if (state.loading > 0) {
        state.loading -= 1;
      }
    },
    setMiniTrolley(state, action) {
      state.miniTrolley = action.payload;
    },
    setActiveSidebar(state, action) {
      state.activeSidebar = action.payload;
    },
    setActiveMenuKey(state, action) {
      state.activeMenuKey = action.payload;
    },
    updateBranch(state, action) {
      const { payload } = action;
      state.branch = payload;
    },
    updateVehicle(state, action) {
      const { payload } = action;
      state.vehicle = payload;
    },
    setActiveAlphabet(state, action) {
      state.activeAlphabet = action.payload;
    },
    updateManualSessionId(state, action) {
      state.manualSessionId = action.payload;
    },
    updateShowCheckStock(state, action) {
      state.showCheckStock = action.payload;
    },
    updateBreadcrumb(state, action) {
      state.breadcrumbs = action.payload?.breadcrumbs;
    },
    updateAppInitailized(state, action) {
      state.appInitailized = action.payload;
    },
    updateLastSearchedTerm(state, action) {
      state.lastSearchedTerm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalAssets.fulfilled, (state, action) => {
      const { storeConfig, ...rest } = action.payload;
      state.tagIcons = rest;
      state.timezone = storeConfig?.timezone || moment.tz.guess();
      state.storeConfig = storeConfig;
      state.GSF_telephone = storeConfig?.contact_mobile_number;
    });
    builder.addCase(fetchGarageVehicleDetails.fulfilled, (state, action) => {
      state.garageVehicle = action.payload;
    });
    builder.addCase(fetchMakeOptions.fulfilled, (state, action) => {
      if (action.payload.length) {
        state.makeOptions = action.payload;
      }
    });
  },
});

//Export Actions Creators
export const {
  updateBranch,
  updateVehicle,
  setMiniTrolley,
  setActiveSidebar,
  updateBreadcrumb,
  endCommonLoading,
  setActiveMenuKey,
  setActiveAlphabet,
  startCommonLoading,
  updateManualSessionId,
  updateShowCheckStock,
  updateAppInitailized,
  updateLastSearchedTerm,
} = common.actions;

//Export selectors
export const getTimezone = (state) => state.common.timezone;
export const getGlobalArgs = (state) => state.common.tagIcons;
export const makeOptions = (state) => state.common.makeOptions;
export const getStoreConfig = (state) => state.common.storeConfig;
export const getGarageVehicle = (state) => state.common.garageVehicle;

export default common;
