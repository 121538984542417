import { handleGraphqlResponse, keys } from "../utils";
import { getCustomerAxios } from "../utils/axios";

export const addVehicleInGarage = async (payload) => {
  const { customer_id, session_id, data, vrm,is_mvl } = payload;
  try {
    const customerAxios = await getCustomerAxios();
    const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
      query: `
     mutation AddGsfMyGarageRecord(
  $customer_id: Int!
  $session_id: String!
  $data: String!
  $vrm: String!
  $is_mvl:Boolean!
) {
  addGsfMyGarageRecord(
    customer_id: $customer_id
    session_id: $session_id
    data: $data
    vrm: $vrm
    is_mvl: $is_mvl
  ) {
    success
    message
  }
}
`,
      variables: {
        customer_id: customer_id,
        session_id: session_id,
        data: data,
        vrm: vrm,
        is_mvl:is_mvl
      },
    });
    const res = handleGraphqlResponse(axiosRes);
    return { data: res.data, error: null };
  } catch (error) {
    return { data: null, error: error.message };
  }
};

export const removeVehicleInGarage = async (id) => {
  try {
    const customerAxios = await getCustomerAxios();
    const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
      query: `mutation DeleteGsfMyGarageRecord(
  $id: Int!
) {
  deleteGsfMyGarageRecord(
    id: $id
  ) {
    success
    message
  }
}
`,
      variables: {
        id: id,
      },
    });
    const res = handleGraphqlResponse(axiosRes);
    return { data: res.data, else: null };
  } catch (error) {
    return { data: null, error: error.message };
  }
};

export const getVehiclesInGarage = async (
  customer_id,
  current_page,
  page_size,
) => {
  try {
    const customerAxios = await getCustomerAxios();
    const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
      query: `query GetGsfMyGarageRecords(
  $customer_id: Int!,
  $page: Int,
  $page_size: Int
) {
  getGsfMyGarageRecords(
    customer_id: $customer_id,
    page: $page,
    page_size: $page_size
  ) {
    records {
      id
      customer_id
      session_id
      data
      vrm
    }
    total_count
    status {
      success
      message
    }
  }
}
`,
      variables: {
        customer_id: customer_id,
        page: current_page,
        page_size: page_size,
      },
    });
    const res = handleGraphqlResponse(axiosRes);
    return { data: res?.data?.getGsfMyGarageRecords, else: null };
  } catch (error) {
    return { data: null, error: error.message };
  }
};

export const getVehicleDetails = async (payload) => {
  const { customer_id, vrm } = payload;
  try {
    const customerAxios = await getCustomerAxios();
    const axiosRes = await customerAxios.post(keys.general.backendGraphqlUrl, {
      query: `query GetGsfMyGarageRecordDetails(
        $customer_id: Int!,
        $vrm: String!
      ) {
        getGsfMyGarageRecordDetails(
          customer_id: $customer_id,
          vrm: $vrm
        ) {
          records {
            id
            customer_id
            session_id
            data
            vrm
          }
          total_count
          status {
            success
            message
          }
        }
      }`,
      variables: {
        customer_id: customer_id,
        vrm: vrm,
      },
    });
    const res = handleGraphqlResponse(axiosRes);

    return { data: res?.data?.getGsfMyGarageRecordDetails, else: null };
  } catch (error) {
    return { data: null, error: error.message };
  }
};
