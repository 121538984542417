import { keys } from "../keys";
import { routes } from "../routes";
import { constants } from "../constants";
import { metaInfo, staticMeta } from "./meta_info";
import { getBlogPageMeta, getCMSPageMeta } from "@/lib/services/cmsPage";
import { getCategoryMeta, getProductMeta } from "@/lib/services/product";
import {
  getHomePage,
  getOrgnizationInfo,
  getContentfulImgData,
} from "@/lib/services/contentful";

export const getCurrentURL = (canonicalPath, includeSearchParams = false) => {
  if (canonicalPath) {
    try {
      const urlSchema = new URL(canonicalPath);
      let currentURL = `${urlSchema.origin}${urlSchema.pathname}`;
      if (!canonicalPath.includes(keys.general.websiteUrl)) {
        const domain = new URL(keys.general.websiteUrl);
        currentURL = `${domain.origin}${urlSchema.pathname}`;
      }

      if (includeSearchParams) {
        currentURL += urlSchema?.search;
      }
      return currentURL;
    } catch (err) {
      console.error(err);
    }
  }
  return "";
};

export const staticMetaInfo = ({ pageURL, canonicalPath, query = false }) => {
  const enableBot = keys.general.enableBot === "1";
  let metaData = {
    ...staticMeta,
    robots: {
      index: enableBot,
      follow: enableBot,
      googleBot: {
        index: enableBot,
        follow: enableBot,
      },
    },
  };

  let currentURL = "";
  if (canonicalPath) {
    currentURL = getCurrentURL(canonicalPath, query);
  } else if (pageURL) {
    currentURL = pageURL;
  }

  if (currentURL) {
    metaData.alternates = { canonical: currentURL };
  }
  return metaData;
};

const capitalizeFirstLetter = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const getMetaImages = async (altText) => {
  try {
    const { data } = await getHomePage();
    if (data?.items?.length > 0 && data.items[0]?.fields?.seo?.fields) {
      const { imageUrl } = getContentfulImgData(
        data.items[0]?.fields?.seo?.fields.shareImage,
      );

      if (imageUrl) {
        return {
          images: {
            url: imageUrl,
            alt: altText,
          },
        };
      }
    }
  } catch (error) {
    console.error("getHomePage Error generating metadata:", error);
  }
  return {};
};

const formatToInternationalUKPhoneNumber = (phoneNumber) => {
  try {
    // Remove any spaces for consistency
    const cleaned = phoneNumber.replace(/\s+/g, "");

    // If the number is already in international format with +44
    if (/^\+44\d{10}$/.test(cleaned)) {
      // Add spaces for readability
      return cleaned.replace(/^\+44(\d{3})(\d{3})(\d{4})$/, "+44 $1 $2 $3");
    }

    // If the number starts with 0, replace the leading 0 with +44 and format it
    if (/^0\d{10}$/.test(cleaned)) {
      return cleaned.replace(/^0(\d{3})(\d{3})(\d{4})$/, "+44 $1 $2 $3");
    }

    // Return the original input if it doesn't match expected formats
    return phoneNumber;
  } catch (err) {
    console.error(err);
    return phoneNumber;
  }
};

const updateMetaObject = (metaObj, res, url, shareImages) => {
  metaObj.title = res?.meta_title || metaObj?.title;
  metaObj.description = res?.meta_description || "";
  metaObj.keywords = res?.meta_keywords || res?.meta_keyword || "";
  metaObj.openGraph = {
    url,
    title: metaObj.title,
    description: metaObj?.description,
    ...shareImages,
  };

  return metaObj;
};

const updateProductMeta = async (productSKU, metaData, currentURL) => {
  const { data, error } = await getProductMeta({ productSKU });
  if (error) {
    console.error("getProductMeta:: error generating metadata:", error);
  } else {
    let metaRes = data?.products?.items?.[0];
    let productImage = {};
    if (metaRes) {
      let productMeta = metaInfo.product;
      let title = metaRes?.name;
      if (metaRes?.extraVariable?.brand_info?.length > 0) {
        title = `${metaRes?.extraVariable?.brand_info[0]?.title} ${title}`;
      }

      metaRes.meta_description = productMeta.meta_description;
      metaRes.meta_title = productMeta.meta_title.replace("{{ TITLE }}", title);

      if (metaRes?.thumbnail?.url) {
        productImage = {
          images: {
            url: metaRes.thumbnail.url,
            alt: metaRes.thumbnail?.label,
          },
        };
      }
      metaData = updateMetaObject(metaData, metaRes, currentURL, productImage);
    }
  }
  return metaData;
};

const updateCategoryMeta = async (categoryid, metaData, currentURL, pageNo) => {
  const { data, error } = await getCategoryMeta({ categoryid });
  if (error) {
    console.error("getCategoryMeta:: error generating metadata:", error);
  } else {
    let shareImages = {};
    const metaRes = data?.categoryList?.[0];
    if (metaRes) {
      let catMeta = metaInfo.category;
      let title = metaRes?.name;
      const addPageNumber = pageNo && Number(pageNo) > 1;
      if (addPageNumber) {
        title += ` - Page ${pageNo}`;
      }

      metaRes.meta_title = catMeta.meta_title.replace("{{ CATEGORY }}", title);
      metaRes.meta_description = catMeta.meta_description.replaceAll(
        "{{ CATEGORY }}",
        title,
      );

      if (addPageNumber) {
        metaRes.meta_description += ` - Page ${pageNo}`;
      }

      if (metaRes?.image) {
        shareImages = {
          images: {
            url: metaRes.image,
            alt: metaRes?.name,
          },
        };
      } else {
        shareImages = await getMetaImages(metaRes?.name);
      }
      metaData = updateMetaObject(metaData, metaRes, currentURL, shareImages);
    }
  }
  return metaData;
};

const updateBranchMeta = async (branch, metaData, currentURL) => {
  let metaRes = metaInfo.branch;
  const location = capitalizeFirstLetter(branch?.location);

  metaRes.meta_title = metaRes.meta_title.replace("{{ LOCATION }}", location);
  metaRes.meta_description = metaRes.meta_description.replace(
    "{{ LOCATION }}",
    location,
  );

  let shareImages = {};
  if (branch?.image?.url) {
    shareImages = {
      images: {
        url: branch?.image?.url,
        alt: branch?.image?.name,
      },
    };
  } else {
    shareImages = await getMetaImages(constants.contentTypes.branch);
  }

  return updateMetaObject(metaData, metaRes, currentURL, shareImages);
};

const getMakeAndModelsPageMeta = async ({
  type,
  metaData,
  currentURL,
  queryParams = {},
}) => {
  let altText = type;
  let metaRes = metaInfo.makeandmodels.archive;
  if (type === constants.contentTypes.modelselected) {
    const make = capitalizeFirstLetter(queryParams?.make || "");
    const model = capitalizeFirstLetter(queryParams?.model || "");

    altText = make + " " + model;
    metaRes = metaInfo.makeandmodels.make_and_model_selected;
    metaRes.meta_title = metaRes.meta_title.replace("{{ MAKE }}", make);
    metaRes.meta_title = metaRes.meta_title.replace("{{ MODEL }}", model);
    metaRes.meta_description = metaRes.meta_description.replace(
      "{{ MAKE }}",
      make,
    );
    metaRes.meta_description = metaRes.meta_description.replace(
      "{{ MODEL }}",
      model,
    );
  } else if (type === constants.contentTypes.makeselected) {
    metaRes = metaInfo.makeandmodels.make_selected;
    altText = capitalizeFirstLetter(queryParams?.make || "");
    metaRes.meta_title = metaRes.meta_title.replace("{{ MAKE }}", altText);
    metaRes.meta_description = metaRes.meta_description.replace(
      "{{ MAKE }}",
      altText,
    );
  }

  console.log(
    "Make And Models Page Meta=====>",
    metaRes.meta_title,
    queryParams,
  );
  const shareImages = await getMetaImages(altText);
  return updateMetaObject(metaData, metaRes, currentURL, shareImages);
};

export const getPageMetaInfo = async ({
  type,
  canonicalPath,
  pageData = {},
}) => {
  let pageString = "";
  let currentURL = getCurrentURL(canonicalPath, true);
  if (pageData?.page_number) {
    currentURL = `${currentURL}?page=${pageData.page_number}`;
    pageString = " - Page " + pageData.page_number;
  }

  const enableBot = keys.general.enableBot === "1";
  let metaData = {
    ...staticMeta,
    alternates: { canonical: currentURL },
    robots: {
      index: enableBot,
      follow: enableBot,
      googleBot: {
        index: enableBot,
        follow: enableBot,
      },
    },
  };

  switch (type) {
    case constants.contentTypes.product:
      metaData = await updateProductMeta(
        pageData?.productSKU,
        metaData,
        currentURL,
      );
      break;
    case constants.contentTypes.category:
      metaData = await updateCategoryMeta(
        pageData?.categoryid,
        metaData,
        currentURL,
        pageData?.page_number,
      );
      break;
    case constants.contentTypes.branches:
      const branchesImage = await getMetaImages(type);
      metaData = updateMetaObject(
        metaData,
        metaInfo.branches,
        currentURL,
        branchesImage,
      );
      break;
    case constants.contentTypes.sitemap:
      let siteMapMeta = metaInfo.misc;
      siteMapMeta.meta_title = siteMapMeta.meta_title.replace(
        "{{ HEADING }}",
        "Sitemap",
      );
      const siteMapImg = await getMetaImages(constants.contentTypes.sitemap);
      metaData = updateMetaObject(
        metaData,
        siteMapMeta,
        currentURL,
        siteMapImg,
      );
      break;
    case constants.contentTypes.search_result:
      let sMeta = { ...metaInfo.search_result };
      sMeta.meta_title = sMeta.meta_title.replace(
        "{{ QUERY }}",
        pageData?.searchText,
      );

      const searchImg = await getMetaImages(pageData?.searchText);
      metaData = updateMetaObject(metaData, sMeta, currentURL, searchImg);
      break;
    case constants.contentTypes.branch:
      metaData = updateBranchMeta(pageData, metaData, currentURL);
      break;
    case constants.contentTypes.makeselected:
    case constants.contentTypes.modelselected:
    case constants.contentTypes.makeandmodels:
      metaData = await getMakeAndModelsPageMeta({
        type,
        metaData,
        currentURL,
        queryParams: pageData,
      });
      break;
    case constants.contentTypes.CMS_PAGE:
      const apiArr = [getMetaImages(type)];
      if (pageData?.relative_url) {
        apiArr.push(getCMSPageMeta(pageData?.relative_url));
      }

      const [imageRes, cmsRes] = await Promise.allSettled(apiArr);
      let dataRes = {};
      if (cmsRes?.value?.data?.blogPostByUrlKey) {
        dataRes = cmsRes?.value?.data?.cmsPage;
      }

      const updateMeta = !dataRes?.meta_title || !dataRes?.meta_description;
      if (updateMeta) {
        if (pageData?.brand) {
          let brandMeta = metaInfo.brand;
          const brand = capitalizeFirstLetter(pageData?.brand);
          dataRes.meta_title = brandMeta.meta_title.replace(
            "{{ BRAND }}",
            brand,
          );
          dataRes.meta_description = brandMeta.meta_description.replace(
            "{{ BRAND }}",
            brand,
          );
        } else if (pageData?.supplier) {
          dataRes = { ...dataRes, ...metaInfo.supplier };
        }
      }

      metaData = updateMetaObject(
        metaData,
        dataRes,
        currentURL,
        imageRes?.value || {},
      );
      break;
    case constants.contentTypes.blogs:
      const [imgRes, blogRes] = await Promise.allSettled([
        getMetaImages(type),
        getBlogPageMeta(pageData.title),
      ]);

      let res = { meta_title: `GSF Car Parts | ${pageData.title}` };
      if (blogRes?.value?.data?.blogPostByUrlKey) {
        res = blogRes?.value?.data?.blogPostByUrlKey;
        res.meta_title = `${res.title} | GSF Car Parts`;
      }
      metaData = updateMetaObject(
        metaData,
        res,
        currentURL,
        imgRes?.value || {},
      );
      break;
  }

  metaData.title += pageString;
  if (metaData?.openGraph) {
    metaData.openGraph.title += pageString;
  }

  return metaData;
};

export const getProductSchema = (product, canonicalPath) => {
  const currentURL = getCurrentURL(canonicalPath);
  const brand = product.brands?.[0] || {};
  const branch = product?.branch || {};
  const homeDeliveryStock = brand?.quantity > 0;
  const muteClickCollect =
    branch?.branch_id && !brand?.branch_stock && !brand?.hub_stock;

  let inStock = true;
  if (!homeDeliveryStock && muteClickCollect) {
    inStock = false;
  }

  return {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: brand?.product_title_with_brand || product.product_title,
    url: currentURL,
    image: product.image,
    description: product.description.html,
    sku: brand?.sku,
    brand: {
      "@type": "Thing",
      name: brand?.title,
    },
    offers: {
      "@type": "Offer",
      url: currentURL,
      priceCurrency: brand?.currency,
      price: brand?.final_price?.substring(1),
      itemCondition: "https://schema.org/NewCondition",
      availability: inStock
        ? "https://schema.org/InStock"
        : "https://schema.org/OutOfStock",
      priceSpecification: {
        price: brand?.final_price?.substring(1),
        priceCurrency: brand?.currency,
        valueAddedTaxIncluded: "https://schema.org/True",
      },
      seller: {
        "@type": "Organization",
        name: "GSF Car Parts",
      },
    },
  };
};

export const getBreadcrumbSchema = (breadcrumbList) => {
  const schema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [],
  };

  const totalLength = breadcrumbList.length;
  breadcrumbList.forEach((item, i) => {
    let listItem = {
      "@type": "ListItem",
      position: i + 1,
      name: item.title,
    };
    if (totalLength !== listItem.position) {
      listItem.item = item.url;
    }
    schema.itemListElement.push(listItem);
  });

  return schema;
};

export const getOrgnizationSchema = async ({
  homeData = null,
  branch = null,
}) => {
  let orgSchema = {
    "@context": "http://schema.org",
    "@type": "Organization",
    url: keys.general.websiteUrl,
    vatID: "135606620",
    name: "GSF Car Parts",
    logo: "",
    image: "",
    telephone: "+44 121 626 7971",
    email: "enquiries@gsfgroup.com",
    description: "GSF Car Parts is a leading automotive parts business.",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+44 121 626 7971",
      email: "enquiries@gsfgroup.com",
    },
    sameAs: ["https://www.facebook.com/gsfcarparts"],
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 105 Midpoint Park Kingsbury Road",
      addressLocality: "Sutton Coldfield",
      addressCountry: "GB",
      addressRegion: "Minworth",
      postalCode: "B76 1AF",
    },
  };

  const { data: orgDdata } = await getOrgnizationInfo();
  if (orgDdata?.items?.length > 0) {
    const fields = orgDdata.items[0]?.fields;
    if (fields?.name) {
      orgSchema.name = fields.name;
      orgSchema.vatID = fields?.vatId || orgSchema.vatID;
      orgSchema.email = fields?.email || orgSchema.email;
      orgSchema.sameAs = fields?.sameAs || orgSchema.sameAs;
      orgSchema.description = fields?.description || orgSchema.description;
      orgSchema.contactPoint.email =
        fields?.email || orgSchema.contactPoint.email;
      orgSchema.address.postalCode =
        fields?.postalCode || orgSchema.address.postalCode;
      orgSchema.address.postalCode =
        fields?.addressCountry || orgSchema.address.addressCountry;
      orgSchema.address.streetAddress =
        fields?.streetAddress || orgSchema.address.streetAddress;
      orgSchema.address.addressRegion =
        fields?.addressRegion || orgSchema.address.addressRegion;
      orgSchema.address.addressLocality =
        fields?.addressLocality || orgSchema.address.addressLocality;
      if (fields?.telephone) {
        const formattedNumber = formatToInternationalUKPhoneNumber(
          fields?.telephone,
        );
        orgSchema.telephone = formattedNumber;
        orgSchema.contactPoint.telephone = formattedNumber;
      }

      const { imageUrl: logoURL } = getContentfulImgData(
        fields?.logo?.fields?.image,
      );
      const { imageUrl } = getContentfulImgData(fields?.image);
      if (logoURL) orgSchema.logo = logoURL;
      if (imageUrl) orgSchema.image = imageUrl;
    }
  }

  if (branch?.branch_id) {
    if (branch?.hyperlink) {
      orgSchema.url += `/branches/${branch.hyperlink}`;
    }

    if (branch?.telephone) {
      const formattedNumber = formatToInternationalUKPhoneNumber(
        branch.telephone,
      );
      orgSchema.telephone = formattedNumber;
      orgSchema.contactPoint.telephone = formattedNumber;
    }

    if (branch?.image?.length > 0) {
      const imageObj = branch.image[0];
      orgSchema.image = imageObj?.url || orgSchema.image;
    }

    orgSchema.name += ` - ${branch?.name}`;
    orgSchema.email = branch?.email || orgSchema.email;
    orgSchema.description = branch?.description || orgSchema.description;
    orgSchema.contactPoint.email =
      branch?.email || orgSchema.contactPoint.email;
    orgSchema.address.streetAddress =
      branch?.address1 || orgSchema.address.streetAddress;
    orgSchema.address.addressLocality =
      branch?.address2 || orgSchema.address.addressLocality;
    orgSchema.address.addressRegion =
      branch?.address3 || orgSchema.address.addressRegion;
    orgSchema.address.postalCode =
      branch?.postcode || orgSchema.address.postalCode;
  } else if (!orgSchema.image && homeData?.seo?.fields?.shareImage) {
    const { imageUrl } = getContentfulImgData(homeData.seo.fields.shareImage);
    if (imageUrl) {
      orgSchema.image = imageUrl;
    }
  }
  return orgSchema;
};

export const getWebSiteSchema = () => {
  return {
    "@context": "https://schema.org",
    "@type": "WebSite",
    url: keys.general.websiteUrl,
    potentialAction: {
      "@type": "SearchAction",
      target: `${
        keys.general.websiteUrl + routes.searchResults
      }?q={search_term_string}`,
      "query-input": "required name=search_term_string",
    },
  };
};
