export const messages = {
  error: {
    requiredField: "This is a required field",
    invalidEmail: "Please enter a valid email address",
    passwordMinLength:
      "Minimum length of this field must be equal or greater than 8 characters. Leading and trailing spaces will be ignored",
    passwordCriterion:
      "Password should have a minimum of 8 characters, at least one small letter, at least one capital letter, at least one number and no space",
    passwordUnmatch: "Password does not match Confirm Password",
    recaptchaUnavailable: "Recaptcha not yet available",
    invalidPhone: "Telephone number doesn't look right.",
    maxPhoneLength: "Maximum length is 14",
    invalidVehicleRegistration: "Invalid vehicle registration number.",
    sessionExpired: "Your session has expired, please login again.",
    invalidInput:"Please enter a valid input value"
  },
  success: {
    login: "Logged in successfully.",
    logout: "Logged out successfully.",
    signUp: "Thank you for registering with GSF Car Parts.",
    newsletterSubscription: "Thank you for your subscription",
    contactForm:
      "Thanks for contacting us with your comments and questions. We'll respond to you very soon.",
    changePassword: "You updated your password.",
    passwordLinkExpire: "Your password reset link has expired",
    productRemoved: "Product successfully removed!",
    productsRemoved: "Products successfully removed!",
  },
  info: {},
};
