export const cartFragments = {
  cartPrice: `prices {
    grand_total {
      ...Money
    }
    applied_taxes {
      amount {
        ...Money
      }
      label
    }
    subtotal_including_tax {
      ...Money
    }
    subtotal_excluding_tax {
      ...Money
    }
    subtotal_with_discount_excluding_tax {
      ...Money
    }
    specialcoupon_discount_amount
    discounts {
      amount {
        ...Money
      }
      label
    }
  }`,
  cartItemFields: `fragment CartItemFields on CartItemInterface {
    uid
    is_available
    prices {
      price {
        ...Money
      }
      price_including_tax {
        ...Money
      }
      row_total {
        ...Money
      }
      row_total_including_tax {
        ...Money
      }
      discounts {
        amount {
          ...Money
        }
        applied_to
        label
      }
      total_item_discount {
        ...Money
      }
    }
    product {
      name
      url_key
      sku
      thumbnail {
        url
        label
      }
      sold_in_pairs
      special_price
      special_from_date
      special_to_date
      extraVariable (breadcrumb_required: true) {
      breadcrumbs {  # Request breadcrumbs
                    id
                    label
                    link
                    component_id
                }
        qty
        fitmentInfo{
          label
          value
      }
        brand_info {
          id
          title
          image
          brand_tier
        }
           breadcrumbs { 
                    label
                }
      }
      price_range {
        minimum_price {
          regular_price {
            value
            currency
          }
          final_price {
            value
            currency
          }
          discount {
            amount_off
            percent_off
          }
        }
      }
        specialCoupon_applied
    }
      
    quantity
  }`,
  addressFields: `fragment AddressFields on CartAddressInterface {
    customer_address_id
    firstname
    lastname
    company
    street
    city
    region {
      code
      label
    }
    postcode
    telephone
    country {
      code
      label
    }
  }`,
  shippingAddress: `fragment ShippingAddress on ShippingCartAddress {
    available_shipping_methods {
      amount {
        ...Money
      }
      available
      carrier_code
      carrier_title
      error_message
      method_code
      method_title
      price_excl_tax {
        ...Money
      }
      price_incl_tax {
        ...Money
      }
    }
    selected_shipping_method {
      amount {
        ...Money
      }
      carrier_code
      carrier_title
      method_code
      method_title
      price_excl_tax {
        ...Money
      }
      price_incl_tax {
        ...Money
      }
    }
  }`,
  ProductInterface: `fragment Products on ProductInterface {
    uid
    show_on_basket
    name
    sku
    type_id
    best_seller
    is_recommended
    url_key
    stock_status
    short_description {
      html
    }
    thumbnail {
      url
      label
    }
    extraVariable (breadcrumb_required: true){
breadcrumbs {  
                    id
                    label
                    link
                    component_id
                }
      qty
      art_nr
      brand_info {
        id
        title
        image
        brand_tier
      }
    }
    sold_in_pairs
    special_price
    special_from_date
    special_to_date
    price_range {
      minimum_price {
        regular_price {
          value
          currency
        }
        final_price {
          value
          currency
        }
        discount {
          amount_off
          percent_off
        }
      }
      maximum_price {
        regular_price {
          value
          currency
        }
        final_price {
          value
          currency
        }
        discount {
          amount_off
          percent_off
        }
      }
    }
      specialCoupon_applied
  }`,
};

export const productFragments = {
  listing: `fragment Products on ProductInterface {
    id
    uid
    sku
    name
    url_key
    best_seller
    special_price
    is_recommended
    sold_in_pairs
    extraVariable (breadcrumb_required: true) {
      qty
      art_nr
      breadcrumbs { label }
      stop_start_compatible
      warranty_guaranteed_text
      brand_info {
        id
        title
        image
        brand_tier
      }
      fitmentInfo {
        label
        value
      }
    }
    thumbnail {
      url
      label
    }
    special_price
    special_from_date
    special_to_date
    price_range {
      minimum_price {
        regular_price {
          value
          currency
        }
        final_price {
          value
          currency
        }
        discount {
          amount_off
          percent_off
        }
      }
    }
      specialCoupon_applied
  }`,
  category: `fragment Category on CategoryInterface {
    id
    name
    description
    component_id
    breadcrumbs {
      category_id
      category_name
      category_level
      category_url_path
      category_url_key
    }
  }`,
  aggregation: `fragment AggregationFragment on Aggregation {
    label
    count
    attribute_code
    options {
      count
      label
      value
    }
  }`,
  detail: `fragment Product on ProductInterface {
    id
    uid
    sku
    name
    url_key
    best_seller
    is_recommended
    description { html }
    sold_in_pairs
    special_price
    special_from_date
    special_to_date
    extraVariable (breadcrumb_required: true) {
      warranty_guaranteed_text
      stop_start_compatible
      qty
      art_nr
      fitmentInfo {
        label
        value
      }
      breadcrumbs { 
                  label
                    }
      brand_info {
        id
        title
        image
        brand_tier
      }
    }
    media_gallery {
      url
      label
    }
    price_range {
      minimum_price {
        regular_price {
          value
          currency
        }
        final_price {
          value
          currency
        }
        discount {
          amount_off
          percent_off
        }
      }
       
    }
     specialCoupon_applied
  }`,
  recently_viewed: `fragment Products on ProductInterface {
    uid
    sku
    name
    type_id
    url_key
    best_seller
    special_price
    is_recommended
    special_to_date
    special_from_date
    sold_in_pairs
    extraVariable (breadcrumb_required: true){
      qty
      art_nr
       breadcrumbs { 
                    label
                }
      brand_info {
        id
        title
        image
        brand_tier
      }
    }
    thumbnail {
      url
      label
    }
    special_price
    special_from_date
    special_to_date
    price_range {
      minimum_price {
        regular_price {
          value
          currency
        }
        final_price {
          value
          currency
        }
        discount {
          amount_off
          percent_off
        }
      }
    }
      specialCoupon_applied
  }`,
};
