export const routes = {
  home: "/",
  //Auth
  signup: "/customer/account/create",
  signin: "/customer/account/login",
  forgotPassword: "/customer/account/forgotpassword",
  logout: "/customer/account/logout-success",

  //Cms
  guestReturns: "/guest-returns",
  privacyPolicy: "/privacy-policy",
  cookiePolicy:"/cookie-policy",
  termsConditions: "/terms-and-conditions",
  deliveryInfo: "/delivery-information",
  contactUs: "/contact",
  clickCollect: "/click-and-collect",
  storeLocator: "/branches",
  knowHub: "/knowledge-hub",
  knowHubBlog: "/knowledge-hub/[slug]",
  klarna: "/klarna",
  aboutUs: "/about-us",
  deals: "/deals",
  parts: "/parts",
  brands: "/brands",
  supplier: "/supplier",
  makesAndModels: "/makesandmodels",
  manualSelection: "/makesandmodels/index/view",
  manualSelectionParts: "/makesandmodels/index/parts",
  sitemap: "/sitemap",
  blog: "/blog",

  //Accout Page
  accountDashboard: "/customer/account",
  newsletter: "/newsletter/manage",
  addNewAddress: "/customer/address/new",
  editAddress: "/customer/address/edit/:id",
  orders: "/customer/orders",
  orderDetails: "/customer/orders/:id",
  changePass: "/customer/account/edit/changepass",
  accountInfo: "/customer/account/edit",
  editAccountInfo: "/customer/account/edit/basic-details",
  editAccountEmail: "/customer/account/edit/change-email",
  addressBook: "/customer/address",
  garage: "/customer/garage",
  vehicleDetails: "/customer/garage/vehicle/:vrm",

  //branches
  branches: "/branches",
  branchDetails: "/branches/:id",

  // PDP screem
  products: "/products",

  //search results screen
  searchResults: "/catalogsearch/result",

  //Cart
  basket: "/checkout/cart",
  shipping: "/checkout/shipping",
  clickCollectCheckout: "/checkout/clickcollect",
  orderConfirmation: "/checkout/success",
  //Third Party
  garageEquipment: "https://gsfgroup.com/garage-essentials",
  veeWee: "https://www.veewee.co.uk",
  careers: "https://www.gsfgroupcareers.com",
  corporateInfo: "https://gsfgroup.com",
  facebook: "https://www.facebook.com/gsfcarparts",
};

export const notToShowBeforeLogin = [
  routes.accountDashboard,
  routes.newsletter,
  routes.addNewAddress,
  routes.editAddress,
  routes.changePass,
  routes.accountInfo,
  routes.editAccountInfo,
  routes.editAccountEmail,
  routes.addressBook,
  routes.garage,
  routes.vehicleDetails,
  routes.orders,
  routes.orderDetails,
];
export const notToShowAfterLogin = [
  routes.signin,
  routes.signup,
  routes.forgotPassword,
];
